<template>
    <div class="task-box">
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>任务中心</el-breadcrumb-item>
            <el-breadcrumb-item>任务标签</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-button @click="add" type="primary" size="small" v-if="tagRules"
             icon="el-icon-plus">添加</el-button>
        </div>
        <!-- content -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="tagList"
            style="width: 100%">
                <el-table-column
                    prop="name"
                    label="标签名称">
                </el-table-column>
                <el-table-column
                    prop="explain"
                    label="备注">
                </el-table-column>
                <el-table-column
                    label="状态">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button size="mini" plain :type="scope.row.status==0?'primary':'info'">
                                {{scope.row.status==0?'启用':'未启用'}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in [0,1]" :key="item" @click.native="changeSatus(scope.row.tid,item)">
                                {{item==0?'启用':'未启用'}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="tagRules"
                    width="100"
                    fixed="right"
                    label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.tid)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
       <!-- addTag-->
        <el-drawer
          title="添加标签"
          :visible.sync="drawer"
          direction="rtl">
            <AddTag @onSubmit="onSubmit"/>
        </el-drawer>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
import AddTag from '@/components/common/AddTag'
export default {
    components:{
        AddTag
    },
    data(){
        return{
            tagRules:checkRules(15),//编辑权限
            drawer:false,
            dialogVisible:false,
            loading:false,
            tagList:[]
        }
    },
    created(){
        this.getTag()
    },
    methods:{
        onSubmit(form){
            //console.log(form)
            if(!form.name||form.name==''){
                this.$message({
                    message: this.CONST.FAIL_LOGIN_PARAMS,
                    type: 'warning'
                });
                return
            }
            form.method = "addTag"
            this.$ajax.post(this.API.api,form).then(res=>{
                //todo
                //console.log(res)
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.loading = false 
                this.drawer = false
                this.getTag()
            }).catch(err=>{
                //err
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        add(){
            this.drawer = true
        },
        handleDelete(tid){
            this.$confirm(this.CONST.DEL_CONFIRM)
                .then(res=> {
                    console.log(res,tid)
                    let params = {
                        method:'delTag',
                        tid:tid
                    }
                    this.loading = true
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        this.getTag()
                        this.$message({
                            message: res.msg?res.msg:this.CONST.DEL_SUC,
                            type: 'success'
                        });
                    }).catch(err=>{
                        //err
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })
                })
                .catch(err => {
                    console.log(err)
                });
        },
        /**
         * 改变可用状态
         */
        changeSatus(tid,status){
            if(!this.tagRules){
                this.$message({
                    message: this.CONST.NO_RULES,
                    type: 'warning'
                });
                return
            }
            let params = {
                method:'changeTagStatus',
                tid:tid,
                status:status
            }
            this.loading  = true 
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.loading = false 
                this.getTag()
            }).catch(err=>{
                //err
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        getTag(){
            let params = {
                method:"getTag",
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                this.loading = false
                this.tagList = res.data
            }).catch(err=>{
                //err
                //console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
.pro-con{
    margin:10px 0;
}
.header-bar{
    margin:10px 0;
}
</style>