<template>
    <div class="add-tag-box">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="名称" required>
                <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.remark" placeholder="备注内容"></el-input>
            </el-form-item>
            <el-form-item label="表单" v-if="types=='project'" required>
                <el-select v-model="keys" placeholder="未选择"
                filterable
                remote
                reserve-keyword
                @change="change"
                :remote-method="search">
                    <el-option
                    v-for="(item,index) in formList"
                    :key="item.id"
                    :label="item.name"
                    :value="index"
                    >
                    <span style="float: left">{{ item.name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="onSubmit">添加</el-button>
            </el-form-item>
        </el-form>
        
    </div>
</template>
<script>
export default {
    props:{
        types:{//tag,project
            type:String
        }
    },
    data(){
        return{
            form:{},
            keys:'',
            formList:[],
            content:''
        }
    },
    methods:{
        change(e){
            //console.log(e)
            if(this.formList[e]){
                this.content = this.formList[e]
            }
        },
        onSubmit(){
            //console.log(this.content.content)
            if(this.types=='project'){
                try {
                    this.form.content = this.content.content
                        if(!this.content.content||this.form.content==''){
                            this.$message({
                            message: "请选择表单",
                            type: 'warning'
                        });
                        return
                    }
                } catch (err) {
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                    return
                }
            }
            this.$emit('onSubmit',this.form)
        },
        search(key){
            console.log(this.keys)
            let params = {
                method:"getForms",
                page:1,
                pageSize:5,
                keys:key
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                console.log(res)
                if(res.data){
                    this.formList = res.data.list
                }
                //this.list = res.data
            }).catch(err=>{
                //Err
                console.log(err)
                this.loading=false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
</style>